<template>
  <div id="sign-in">
    <el-row type="flex" class="member-login-row" justify="space-between">
      <span class="text-primary text-bold text-no-wrap m-auto">
        {{ headerTitle }}
      </span> 
      <el-divider />
    </el-row>
    <div class="content bg-white">
      <!-- OTP -->
      <template v-if="isOTP">
        <div class="otp-container">
          <p class="text-center">{{ $t("CHECK_EMAIL_FOR_OTP") }}</p>
          <v-otp-input
            class="otp"
            ref="otpInput"
            :inputClasses="isOTPError ? 'error otp-input' : 'otp-input'"
            :numInputs="4"
            separator=" "
            :shouldAutoFocus="true"
            @on-complete="(value) => this.otp = value"
            @on-change="(value) => this.otp = value"
          />
          <small v-if="isOTPError" class="error-msg">{{ $t("WRONG_OTP") }}</small>
          <p class="otp-countdown">
            <span v-if="currentTime == 'requesting'">{{ $t("REQUESTING") }}</span>
            <span v-else-if="currentTime">{{ $t("RESEND_CODE_IN", { time: currentTime }) }}</span>
            <el-button
              v-else
              class="button secondary white link-style"
              v-loading="isLoading"
              @click="forgotPW()"
            >
              <b>{{ $t("RESEND_CODE") }}</b>
            </el-button>
          </p>
          <small class="otp-desc">
            **{{ $t("OTP_DESC") }}
          </small>
          <el-button
            class="button primary w-100"
            :disabled="otp.length < 4"
            @click="checkOTP"
          > {{ $t("SEND") }} </el-button>
          <el-button
            class="button link-style w-100"
            @click="isOTP = false"
          >{{ $t("CANCEL") }}</el-button>
        </div>
      </template>

      <!-- Sign in -->
      <template v-else>
        <el-form 
          :model="detail"
          ref="detail"
          label-position="top"
          :rules="rules"
          @submit.native.prevent="submitForm()">
          <el-form-item v-if="!isChangingPW" :label="$t('EMAIL')" prop="email">
            <el-input
              type="text"
              v-model="detail.email"
              :placeholder="$t('EMAIL_PLACEHOLDER')"
            >
            <template slot="prefix">
              <i class="custom-icon icon-email-placeholder-prefix mini"/>
            </template>
            </el-input>
          </el-form-item>
          <el-form-item v-if="!isForgotPW" :label=" $t(isChangingPW ? 'NEW_PASSWORD' : 'PASSWORD')" prop="password">
            <el-input
              ref="password"
              :type="passwordType"
              v-model="detail.password"
              :placeholder="$t('PASSWORD_PLACEHOLDER')"
            >
              <template slot="prefix">
                <i class="custom-icon icon-password-placeholder-prefix mini"/>
              </template>
              <template slot="suffix">
                <i class="custom-icon mini pointer" 
                  :class="passwordType === 'password' ? 'icon-eye-show' : 'icon-eye-hide'"
                  @click="showPwd('password')"
                />
              </template>
            </el-input>
          </el-form-item>
          <!-- Only for change password -->
          <el-form-item v-if="isChangingPW" class="w-100" :label="$t('RETYPE_PASSWORD')" prop="confirmPassword">
            <el-input
              ref="confirmPassword"
              :type="confirmPasswordType"
              v-model="detail.confirmPassword"
              :placeholder="$t('PASSWORD_PLACEHOLDER')"
            >
              <template slot="prefix">
                <i class="custom-icon icon-password-placeholder-prefix mini"/>
              </template>
              <template slot="suffix">
                <i class="custom-icon mini pointer" 
                  :class="confirmPasswordType === 'password' ? 'icon-eye-show' : 'icon-eye-hide'"
                  @click="showPwd('confirmPassword')"
                />
              </template>
            </el-input>
          </el-form-item>

          <!-- Forgot password actions -->
          <el-row v-if="isForgotPW" type="flex" justify="center" align="middle" :gutter="30">
            <el-col :span="isMobile ? 24 : 10">
              <el-button class="w-100 button black reversed-color" :loading="isLoading" @click="isForgotPW = false">
                {{ $t("BACK_TO_LOGIN") }}
              </el-button>
            </el-col>
            <el-col :span="isMobile ? 24 : 14">
              <el-button class="w-100 button primary" :loading="isLoading" native-type="submit">
                {{ $t("SEND") }}
              </el-button>
            </el-col>
          </el-row>

          <!-- Change password actions -->
          <el-row v-else-if="isChangingPW" type="flex" justify="center" align="middle" :gutter="30">
            <el-col :span="isMobile ? 24 : 10">
              <el-button class="w-100 button black reversed-color" :loading="isLoading" @click="isChangingPW = false">
                {{ $t("CANCEL") }}
              </el-button>
            </el-col>
            <el-col :span="isMobile ? 24 : 14">
              <el-button class="w-100 button primary" :loading="isLoading" native-type="submit">
                {{ $t("SEND") }}
              </el-button>
            </el-col>
          </el-row>

          <!-- Sign in actions -->
          <template v-else>
            <el-button class="w-100 button black reversed-color" :loading="isLoading" native-type="submit">
              {{ $t("LOG_IN") }}
            </el-button>
            <el-button class="w-100 button link-style" :loading="isLoading" @click="isForgotPW = true">
              {{ $t("FORGOT_PASSWORD") }}
            </el-button>
            <el-divider/>
            <el-button class="w-100 button black" :loading="isLoading" @click="goTo('sign-up')">
              {{ $t("SIGN_UP") }}
            </el-button>
          </template>

        </el-form>
      </template>

    </div>
    <el-row class="back-row" type="flex" justify="center" align="middle">
      <i class="custom-icon icon-arrow-left mini"/>
      <span class="pointer" @click="goTo('/')">
        {{ $t("BACK_TO_HOME") }}
      </span>
    </el-row>
  </div>
</template>

<script>
import { generalMixin } from "@/utils/general-mixin.js"
import { forgotPassword, otpValidatation, changePassword } from "@/api/auth"
import { validateEmail } from "@/utils/helpers"
import moment from "moment";
import cloneDeep from "lodash/cloneDeep"
import OtpInput from "@bachdgvn/vue-otp-input";

const emptyForm = {
  email: "",
  password: "",
  confirmPassword: "", // for forgot password
}

export default {
  name: "SignIn",
  mixins: [ generalMixin ],
  components: {
    "v-otp-input": OtpInput,
  },
  data() {
    return {
      isLoading: false,
      detail: cloneDeep(emptyForm),
      passwordType: "password",
      confirmPasswordType: "password", // for forgot password
      isForgotPW: false,
      isOTP: false,
      currentTime: "requesting",
      otp: '',
      isOTPError: false,
      isChangingPW: false,
      intervalId: null,
      
    }
  },
  computed: {
    rules() {
      const pwRules = {
        password: [{ validator: this.validatePass, trigger: "blur" }],
        confirmPassword: [{ validator: this.validatePass2, trigger: "blur" }],
      }
      const signInRules = {
        email: [
          { required: true, message: this.$t("EMAIL_REQUIRED"), trigger: "blur" },
          { validator: this.validateEmail, trigger: "blur" }
        ],
      }

      let result
      if (this.isChangingPW) {
        result = pwRules
      } else {
        result = signInRules
        if (this.isForgotPW == false) {
          result.password = [{ required: true, message: this.$t("PASSWORD_REQUIRED"), trigger: "blur" }]
        }
      }

      return result
    },
    headerTitle() {
      if (this.isForgotPW || this.isOTP || this.isChangingPW) {
        return this.$t("FORGOT_PASSWORD")
      } else {
        return this.$t("MEMBER_LOGIN")
      }
    }
  },
  methods: {
    validateEmail(_, value, callback) {
      if (validateEmail(value) == null) {
        callback(new Error(this.$t("EMAIL_INVALID")))
      } else {
        callback()
      }
    },
    showPwd(type) {
      if (this[type+'Type'] === 'password') {
        this[type+'Type'] = ''
      } else {
        this[type+'Type'] = 'password'
      }
      this.$nextTick(() => {
        this.$refs[type].focus()
      })
    },
    submitForm() {
      this.$refs["detail"].validate(valid => {
        if (valid) {
          if (this.isForgotPW) {
            this.forgotPW()
          } else if (this.isChangingPW) {
            this.changePW()
          } else {
            this.signIn()
          }
        } else {
          this.$notify({
            message: this.$t("INVALID_FORM_MESSAGE"),
            type: 'error',
            duration: this.NOTIFY_DURATION,
            position: this.NOTIFY_POSITION
          })
        }
      })
    },
    signIn() {
      this.isLoading = true
      this.$store.dispatch('auth/login', this.detail)
      .then(res => {
          if (res.result?.firstTimeLogin == true) {
            this.$notify({
              message: this.$t("FIRST_TIME_LOGIN_MSG"),
              type: 'info',
              duration: 8000,
              position: this.NOTIFY_POSITION
            })
            this.$router.push('/bo/profile')
          } else {
            if (res.result.language?.code != null) {
              this.$store.dispatch('app/setLocale', { value: res.result.language?.code, updateBE: false })
            }
            if (this.$store.state.auth.prevPath != null) {
              this.$router.push(this.$store.state.auth.prevPath)
              this.$store.commit('auth/SET_PREV_PATH', null)
            } else {
              this.$router.push('/bo/dashboard')
            }
          }
      }).finally(() => {
        this.isLoading = false
      })
    },
    forgotPW() {
      this.isLoading = true
      forgotPassword(this.detail)
      .then(res => {
        if (res.status == 'ok' || res.timeLeft != null) {
          this.isForgotPW = false
          this.isOTP = true
          this.detail.password = ""
        }
        // if error, BE will return the timeLeft
        // else, timeLeft == null
        this.OTPTimer(res.timeLeft);
      }).finally(() => {
        this.isLoading = false
      })
    },
    OTPTimer(timeLeft = null) {
      const duration = moment.duration(timeLeft || 180, "s");
      // console.log(duration)
      if (this.intervalId != null) {
        clearInterval(this.intervalId)
      }
      this.intervalId = setInterval(() => {
        duration.subtract(1, "s");
        const inMilliseconds = duration.asMilliseconds();
        this.currentTime = moment.utc(inMilliseconds).format("HH:mm:ss");

        if (inMilliseconds > 0) return;
        clearInterval(this.intervalId);
        this.intervalId = null
        // console.warn("Times up!");
        this.currentTime = null;
      }, 1000);
    },
    checkOTP() {
      const data = {
        code: this.otp,
        user: { email: this.detail.email }
      }
      this.isLoading = true
      otpValidatation(data).then(response => {
        if (response.status == 'ok') {
          this.isOTPError = false
          this.isOTP = false
          this.isChangingPW = true
        } else {
          this.$refs['otpInput'].clearInput();
          this.isOTPError = true
        }
      }).finally(() => this.isLoading = false)
    },

    // CHANGE Password
    validatePass(_, value, callback) {
      if (value === "") {
        callback(new Error(this.$t("PASSWORD_INVALID_2")));
      } else {
        if (this.detail.confirmPassword !== "") {
          this.$refs.detail.validateField("confirmPassword");
        }
        callback();
      }
    },
    validatePass2(_, value, callback) {
      if (value === "") {
        callback(new Error(this.$t("PASSWORD_INVALID_4")));
      } else if (value !== this.detail.password) {
        callback(new Error(this.$t("PASSWORD_INVALID_5")));
      } else {
        callback();
      }
    },
    changePW() {
      this.$refs['detail'].validate(valid => {
        if (valid) {
          this.isSubmitting = true
          changePassword({
            otp: this.otp,
            newPassword: this.detail.password,
            email: this.detail.email
          }).then(res => {
            if (res?.status == "ok") {
              this.$notify({
                title: this.$t("SUCCESS"),
                message: this.$t("CHANGE_PASSWORD_SUCCESS"),
                type: 'success',
                duration: this.NOTIFY_DURATION,
                position: this.NOTIFY_POSITION
              })
              this.$router.go()
            }
          })
          .finally(() => this.isSubmitting = false)
        } else {
          this.$notify({
            message: this.$t("INVALID_FORM_MESSAGE"),
            type: 'error',
            duration: this.NOTIFY_DURATION,
            position: this.NOTIFY_POSITION
          })
        }
      })
    },
  },
  beforeRouteEnter(_, from, next) {
    next(vm => {
      const firstMatchedFrom = from.matched[0]?.path
      if (firstMatchedFrom !== "/auth") {
        vm.$store.commit('auth/SET_PREV_PATH', from.fullPath)
      }
      return true
    })
  }

}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";

#sign-in {
  width: 494px;
  margin: auto;

  .member-login-row {
    >span {
      margin-right: 10px;
      font-family: $fontBaseBold;
    }
  }
  .bg-white {
    border-radius: 5px;
  }
  .content {
    box-shadow: 0px 3px 15px #00000015;
  }
  .el-form {
    padding: 1rem 10px 3rem;
    .el-input__inner {
      border: 1px solid #F0F0F0;
      padding-left: 40px;
    }
    .el-input input {
      height: 48px;
    }
    .el-input__prefix {
      top: 12px;
      left: 10px;
    }
    .el-input__suffix {
      top: 12px;
      right: 10px;
    }
    .el-button { 
      margin: 0.25rem auto;
      font-family: $fontBaseBold;
      height: 48px;
    }
    .el-divider { margin: 18px auto; background-color: #F0F0F0; }
  }

  .back-row {
    position: fixed;
    bottom: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    width: 100vw;
    left: 0;
    text-align: center;
    background: #F3F5FA 0% 0% no-repeat padding-box;
    padding: 1.5rem 0;
    font-family: $fontBaseBold;
    color: #A8A8A8;

    >i { margin-right: 5px }
  }

  .otp-container {
    padding: 2rem;
    .otp {
      width: 100%;
      justify-content: space-around;
      margin-top: 2rem;
    }
    .otp-input {
      width: 40px;
      height: 40px;
      padding: 5px;
      margin: 0 10px;
      font-size: 20px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      text-align: center;
    }
    .otp-countdown {
      text-align: center;
      margin-top: 1rem;
    }
    .otp-desc {
      margin-top: 0.5rem;
      width: 100%;
      text-align: center;
      display: block;
      margin-bottom: 1rem;
    }
    .error {
      border: 1px solid $red !important;
    }
    .error-msg {
      margin-top: 1rem;
      color: $red;
      display: block;
      text-align: center;
    }
    .el-button {
      margin-top: 1rem;      
    }
  }


  .el-button + .el-button { margin-left: 0 } // to override el default class
}

.mobile {
  #sign-in {
    width: 100%;
    margin: 0;
  }
}
</style>